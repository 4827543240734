import ModalMessage from "../../../molecules/ModalMessage";
import BookOpenOutlineIcon from "mdi-react/BookOpenOutlineIcon";
import React from "react";
import inputs from "../../../molecules/inputsWithContextAndSurrounds";
import {connect} from "react-redux";
import deltas from "../../../../redux/actions/deltas";
import signals from "../../../../redux/actions/signals";
import courseInformationModalStates from "../../../../redux/states/courseInformationModal";
import fields from "../../../../redux/fields/courseInformation";
import FormContext from "../../../FormContext";
import DomainIcon from "mdi-react/DomainIcon";
import MapMarkerIcon from "mdi-react/MapMarkerIcon";
import {filterCourseOptions, isCourseActive} from "../../enrolments/modals/EnrolmentModal";

const {SelectInput} = inputs;

const {
    updateCourseInformationForm,
} = deltas.actionCreators;

const {
    closeCourseInformationModal,
    submitCourseInformationForm,
} = signals.actionCreators;

const {
    OPEN_STATIC,
    REQUESTING,
} = courseInformationModalStates;

const mapStateToProps = state => {
    const formState = state.forms.courseInformation;

    const chosenProviderId = formState[fields.CURRENT_ENROLMENT_PROVIDER_ID].value;
    const chosenCourseId = formState[fields.CURRENT_ENROLMENT_COURSE_ID].value;

    const chosenCourse = (Object.entries(state.data.courses).find(([courseId]) => chosenCourseId === parseInt(courseId)) || [null,null])[1];
    const regionsAvailableForChosenCourse = chosenCourse
        ? Object.entries(state.data.regions).filter(([regionId]) => (chosenCourse || {regionIds: []}).regionIds.includes(parseInt(regionId)))
            .map(([id,{name}]) => ({
                label: name,
                value: parseInt(id),
            }))
        : [];

    const deliveryTypesAvailableForChosenProvider = chosenProviderId
        ? Object.entries(state.data.deliveryTypes)
            .filter(([, {providers}]) => providers.includes(parseInt(chosenProviderId)))
            .map(([id, {name}]) => ({
                label: name,
                value: parseInt(id),
            }))
        : [];

    // Possible courses based on ITP data. ITP courses for ITPs, non-ITP courses for non-ITPs
    const possibleCoursesForSelector = Object.keys(state.data.courses)
        .reduce((possibleCourses, key) => {
            const course = state.data.courses[key];
            if (course.isItp === state.data.profile.isItpSchool && isCourseActive(course.startDate, course.endDate)) {
                possibleCourses[key] = course;
            }
            return possibleCourses;
        }, {});

    // Visible providers based on isVisibleToCareersLeads and at least one possibleCourse available
    const providersForSelector = Object.keys(state.data.providers)
        .reduce((visibleProviders, key) => {
            if (
                state.data.providers[key].isVisibleToCareersLeads
                && Object.entries(possibleCoursesForSelector)
                    .some(([, pc]) => pc.providerId === state.data.providers[key].id)
            ) {
                visibleProviders[key] = state.data.providers[key];
            }
            return visibleProviders;
        }, {});

    return {
        formState,
        isCourseInformationModalOpen: [OPEN_STATIC, REQUESTING].includes(state.states.courseInformationModal.state),
        isCourseInformationModalRequesting: REQUESTING === state.states.courseInformationModal,
        providerOptions: Object.entries(providersForSelector)
            .map(([id, {name}]) => ({
                label: name,
                value: parseInt(id),
            })),
        courseOptions: chosenProviderId
            ? Object.entries(possibleCoursesForSelector)
                .filter(([, {providerId}]) => chosenProviderId === providerId)
                .map(([id, {name}]) => ({
                    label: name,
                    value: parseInt(id),
                }))
            : [],
        regionOptions: regionsAvailableForChosenCourse,
        deliveryTypeOptions: deliveryTypesAvailableForChosenProvider,
    };
};

const mapDispatchToProps = {
    updateCourseInformationForm,
    closeCourseInformationModal,
    submitCourseInformationForm,
};

const CourseInformationModal = ({formState, updateCourseInformationForm, isCourseInformationModalOpen, isCourseInformationModalRequesting, closeCourseInformationModal, submitCourseInformationForm, providerOptions, courseOptions, regionOptions, deliveryTypeOptions}) =>
    <ModalMessage
        title="Edit your Course Information"
        isOpen={isCourseInformationModalOpen}
        isRequesting={isCourseInformationModalRequesting}
        closeModal={closeCourseInformationModal}
        submitForm={submitCourseInformationForm}
        overrideSaveButtonText={'Complete Selection'}
    >
        <FormContext.Provider value={{formState: formState, updater: updateCourseInformationForm}}>
            <p className="mb-4">
                Please select the <strong>Provider, Course and Region and Training Delivery Type</strong> that you wish to sign up to. Once you have completed your selection you will be emailed with the next steps.
            </p>
            <div className="mb-3" data-cy="course-provider-selector">
                <SelectInput
                    id="o-courseOrganisation"
                    label="Provider"
                    placeholder="Select provider"
                    icon={<DomainIcon />}
                    options={providerOptions}
                    isGrouped={false}
                    field={fields.CURRENT_ENROLMENT_PROVIDER_ID}
                    required
                />
            </div>
            <div className="mb-3" data-cy="course-course-selector">
                <SelectInput
                    id="o-profileCourse"
                    label="Course"
                    placeholder="Select course"
                    icon={<BookOpenOutlineIcon />}
                    options={courseOptions}
                    isGrouped={false}
                    field={fields.CURRENT_ENROLMENT_COURSE_ID}
                    required
                />
            </div>
            <div className="mb-3" data-cy="course-region-selector">
                <SelectInput
                    id="o-courseRegion"
                    label="Region I want to complete training in"
                    placeholder="Select region"
                    icon={<MapMarkerIcon />}
                    options={regionOptions}
                    isGrouped={false}
                    field={fields.CURRENT_ENROLMENT_REGION_ID}
                    required
                />
            </div>
            <div className="mb-3" data-cy="course-training-selector">
                <SelectInput
                    id="o-deliveryType"
                    label="Training Delivery Type"
                    placeholder="Select training delivery type"
                    icon={<MapMarkerIcon />}
                    options={deliveryTypeOptions}
                    isGrouped={false}
                    field={fields.CURRENT_ENROLMENT_DELIVERY_TYPE_ID}
                    required
                />
            </div>
        </FormContext.Provider>
</ModalMessage>;

export default connect(mapStateToProps, mapDispatchToProps)(CourseInformationModal);
