import EnrolmentModalSectionTitle from "../../../../molecules/enrolments/EnrolmentModalSectionTitle";
import {Collapse} from "reactstrap";
import CalendarBlankIcon from "mdi-react/CalendarBlankIcon";
import PhoneIcon from "mdi-react/PhoneIcon";
import ReadOnlyField from "../../../../atoms/ReadOnlyField";
import AlertMessage from "../../../../atoms/AlertMessage";
import React, {useState} from "react";
import inputs from "../../../../molecules/inputsWithContextAndSurrounds";
import fields from "../../../../../redux/fields/enrolment";
import Button from "../../../../atoms/Button";

const {TextInput, DateInput} = inputs;

export default ({isCec, firstName, lastName, urn, schoolName, email, schoolRegionName, signedUpAt, phone, isEnrolmentLockedDueToReleased, isItpSchool}) => {
    const [collapse, setCollapse] = useState(false);

    return <div className="mb-5">
        <EnrolmentModalSectionTitle
            title={'Personal Information'}
            {...{collapse, setCollapse}}
        />
        <Collapse isOpen={collapse}>
            <div className="bg-light py-3 px-4 px-md-5">
                {!isCec &&
                    <div className="mb-4">
                        <AlertMessage>
                            <p className="mb-0">
                                You are unable to change the personal information of this Careers Leader.
                            </p>
                            <p className="mb-0">
                                If required, please contact CEC to edit these fields.
                            </p>
                        </AlertMessage>
                    </div>
                }
                <div className="row">
                    <div className="col-md-6 mb-3">
                        <ReadOnlyField value={firstName} label="First Name"/>
                    </div>
                    <div className="col-md-6 mb-3">
                        <ReadOnlyField value={lastName} label="Surname"/>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6 mb-3">
                        <ReadOnlyField value={email} label="Email"/>
                    </div>
                    {isCec
                        ? <div className="col-12 col-md-6 mb-3">
                            <TextInput id="o-phone" label="Phone" placeholder="Enter a phone number"
                                       icon={<PhoneIcon/>} field={fields.PHONE} required maxChars={25}/>
                        </div>
                        : <div className="col-md-6 mb-3">
                            <ReadOnlyField value={phone} label="Contact Number"/>
                        </div>
                    }
                </div>
                <div className="row">
                    <div className="col-12 col-md-6 mb-3">
                        <ReadOnlyField value={schoolName} label="School Name"/>
                    </div>
                    <div className="row col-12 col-md-6">
                        <div className="col-md-6 mb-3">
                            <ReadOnlyField value={urn} label={isItpSchool ? 'UKPRN' : 'URN'}/>
                        </div>
                        <div className="col-md-6 mb-3">
                            <ReadOnlyField value={schoolRegionName} label="Region"/>
                        </div>
                    </div>
                </div>
                <div className="row">
                    {isCec
                        ? <>
                            <div className="col-12 col-md-6 mb-3">
                                <DateInput id="o-schoolStartDate" label="School Start Date"
                                           placeholder="Select the school sign up date"
                                           field={fields.SCHOOL_START_DATE} icon={<CalendarBlankIcon/>} required/>
                            </div>
                            <div className="col-12 col-md-6 mb-3">
                                <DateInput id="o-signUpDate" label="Course Sign Up Date" placeholder="Select the course sign up date" field={fields.SIGNED_UP_AT} disabled={isEnrolmentLockedDueToReleased} icon={<CalendarBlankIcon />} required />
                            </div>
                        </>
                        : <div className="col-md-6 mb-3">
                            <ReadOnlyField value={signedUpAt} label="Course Sign Up Date"/>
                        </div>
                    }
                </div>
            </div>
        </Collapse>
    </div>;
}
