import ProfileSectionTitle from "../../molecules/careers-lead-profile/ProfileSectionTitle";
import ProfileSectionTitleButton from "../../atoms/careers-lead-profile/ProfileSectionTitleButton";
import AlertMessage from "../../atoms/AlertMessage";
import React from "react";
import DataLayout from "../../atoms/careers-lead-profile/DataLayout";
import {connect} from "react-redux";
import PrivacyPermission from "../../molecules/careers-lead-profile/PrivacyPermission";
import signals from "../../../redux/actions/signals";
import CommsPermission from "../../molecules/careers-lead-profile/CommsPermission";

const personalInformationMap = {
    'First Name': 'firstName',
    'Surname': 'lastName',
    'Email': 'email',
    'Phone Number': 'phone',
};

const {
    toggleIsPrivacyAgreed,
    toggleIsCommsAgreed,
    openPersonalInformationModal,
    openSchoolAndLoginModal,
} = signals.actionCreators;

const mapStateToProps = (state) => ({
    profile: state.data.profile,
});

const mapDispatchToProps = {
    toggleIsPrivacyAgreed,
    toggleIsCommsAgreed,
    openPersonalInformationModal,
    openSchoolAndLoginModal,
};

const PersonalInformationSection = ({profile, toggleIsPrivacyAgreed, openPersonalInformationModal, openSchoolAndLoginModal, toggleIsCommsAgreed}) => {
    const schoolInformationMap = {
        'schoolName': 'School Name',
        'urn': profile.isItpSchool ? 'UKPRN' : 'URN',
        'regionName': 'Region',
    };

    return (<div className="col-12 col-md-6 d-flex mb-3 mb-md-0">
            <div className="section__block">
                <ProfileSectionTitle
                    title={'Personal Information'}
                    button={
                        <ProfileSectionTitleButton
                            onClick={openPersonalInformationModal}
                        />
                    }
                />
                {/* Personal Information */}
                <div className="row mb-3">
                    {Object.entries(personalInformationMap).map(
                        ([label, key]) => <DataLayout
                            key={key}
                            item={{label, value: profile[key]}}
                        />
                    )}
                </div>
                {/* School Information */}
                <div className="bg-light border p-3 mb-4">
                    <div className="row mb-0">
                        {Object.entries(schoolInformationMap).map(
                            ([key, label], i, entries) => <DataLayout
                                    key = {key}
                                    item = {{label: label, value: profile[key]}}
                                    marginBottom = {2 * Math.ceil(i / 2) === entries.length - 1 ? 0 : undefined}
                                />
                            )}
                    </div>
                    {/* Commented in advance of removing this feature completely, as it could cause problems now that CLs can have multiple non-released enrolments /*/}
                    {/*<AlertMessage>*/}
                    {/*    <p className="mb-0">*/}
                    {/*        <strong>Want to change your school? </strong>*/}
                    {/*        Edit your <button className="button-link link-teal-to-gray" onClick={openSchoolAndLoginModal}>School and Login Information</button>.*/}
                    {/*    </p>*/}
                    {/*</AlertMessage>*/}
                </div>
                {/* Privacy Permission */}
                <PrivacyPermission
                    isPrivacyAgreed={profile.isPrivacyAgreed}
                    {...{toggleIsPrivacyAgreed}}
                />
                {/* Privacy Permission */}
                <CommsPermission
                    isCommsAgreed={profile.isCommsAgreed}
                    {...{toggleIsCommsAgreed}}
                />
            </div>
        </div>)
    };

export default connect(mapStateToProps, mapDispatchToProps)(PersonalInformationSection);
